/* eslint-disable react/prop-types */
import style from "./bodyRowPage.module.scss";

type BodyRowPageProps = {
  children: React.ReactNode;
  paddingTop?: number;
  paddingBottom?: number;
};

const BodyRowPage: React.FC<BodyRowPageProps> = ({ children, paddingTop, paddingBottom }) => {
  return (
    <div className={style.bodyRowPage} style={{ paddingTop: `${paddingTop}px`, paddingBottom: `${paddingBottom}px` }}>
      {children}
    </div>
  );
};

export default BodyRowPage;
